const index = () => import(/* webpackChunkName: court */ "../views/court/index.vue")
const list = () => import(/* webpackChunkName: court */ "../views/court/page/list.vue")

export default {
    path: "/court",
    name: "court",
    component: index,
    children: [
        {path: 'list/:venueId', name: 'court-list', component: list, backRouteName: 'Home'}
    ]
}
