import main from './mainConfig'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

window.onerror = (message, source, lineno, colno, error) => {
}
let mainData = new main({router, store, render: h => h(App)})

new Vue(mainData.options).$mount("#app");
