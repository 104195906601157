import Vue from 'vue';
import { TimePicker, TimeSelect, Dialog, DatePicker, Select, Option, Card, Button, Input } from 'element-ui';
Vue.use(TimePicker);
Vue.use(TimeSelect);
Vue.use(Dialog);
Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Option);
Vue.use(Card);
Vue.use(Button);
Vue.use(Input);






// import {
//     Pagination,
//     Dialog,
//     Autocomplete,
//     Dropdown,
//     DropdownMenu,
//     DropdownItem,
//     Menu,
//     Submenu,
//     MenuItem,
//     MenuItemGroup,
//     Input,
//     InputNumber,
//     Radio,
//     RadioGroup,
//     RadioButton,
//     Checkbox,
//     CheckboxButton,
//     CheckboxGroup,
//     Switch,
//     Select,
//     Option,
//     OptionGroup,
//     Button,
//     ButtonGroup,
//     Table,
//     TableColumn,
//     DatePicker,
//     TimeSelect,
//     TimePicker,
//     Popover,
//     Tooltip,
//     Breadcrumb,
//     BreadcrumbItem,
//     Form,
//     FormItem,
//     Tabs,
//     TabPane,
//     Tag,
//     Tree,
//     Alert,
//     Slider,
//     Icon,
//     Row,
//     Col,
//     Upload,
//     Progress,
//     Spinner,
//     Badge,
//     Card,
//     Rate,
//     Steps,
//     Step,
//     Carousel,
//     CarouselItem,
//     Collapse,
//     CollapseItem,
//     Cascader,
//     ColorPicker,
//     Transfer,
//     Container,
//     Header,
//     Aside,
//     Main,
//     Footer,
//     Timeline,
//     TimelineItem,
//     Link,
//     Divider,
//     Image,
//     Calendar,
//     Backtop,
//     PageHeader,
//     CascaderPanel,
//     Loading,
//     MessageBox,
//     Message,
//     Notification
//   } from 'element-ui';