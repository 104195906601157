<!--  -->
<template>
<!-- :style="{...appStyle}" -->
    <div id="app" class="app"  @click="appClick">
        <div id="nav" class="app-item head">
            <app-head ref="head" v-if="appLoaded"></app-head>
        </div>
        <div class="app-item content">
            <router-view
            v-if="appLoaded && mountedState"
            ref="pageNow"
            :appStyle.sync="appStyle"
            :appSizeData.sync="appSizeData"
            :htmlFontSize.sync="htmlFontSize"
             />
        </div>
        <tokenInput ref="tokenInput"></tokenInput>
        <loading ref="loading" class="app-loading"></loading>
        <loading ref="clientLoading" class="client-loading"></loading>
        <div class="quit-box">
            <quit @quit="quit"></quit>
        </div>
    </div>
</template>

<script>
    import appClass from '@/API/page/app'
    import appStyle from './global/js/appStyle'

    export default {
        name: '',
        props: {},
        mixins: [appStyle],
        data() {
            return {
                api: new appClass(),
                appData: new this.appDataClass({key: 'app'}),
                appStyle: {},
                htmlFontSize: 0,
                appSizeData: {},
                appLoaded: true,
                mountedState: false,
                tokenState: null
            };
        },
        methods: {
            
            appClick(e){
                this._common.appClick(e)
            },
            async userTokenCheck(){
                let token = this.appData.getItem('token')
                console.log(token)
                let state = false
                if(token){
                    let res = await this.api.checkToken({token})
                    console.log(res)
                    state = res ? true : false
                }
                if(!state) this.tokenInputOpen()
                
            },
            async tokenInputOpen(){
                this._errorHand.hand({code: 'invalid token'})
                await this._common.settimeout(1000)
                let res = await this.$refs.tokenInput.open()
                this.userTokenCheck()
            },
            paramsSet(){
                let loading = this.$refs.loading
                this.PARAMS.loading = loading
                this._common.clientLoading = this.$refs.clientLoading
            },
            quit(){
                this.userTokenCheck()
            }
        },
        created() {
        },
        components: {
        },
        computed: {
        },
        watch: {
            // tokenState: {
            //     handler(val){
            //         if(val === false) this.$refs.tokenInput.open()
            //     },
            //     deep: true,
            //     immediate: true
            // },
        },
        mounted() {
            this.htmlFontSizeSet()
            window.onresize = (e) => {
                this.htmlFontSizeSet(e)
                this._common.emitEventBus('onresize')
            }
            this.mountedState = true
            this.userTokenCheck()
            this.paramsSet()

            
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
// html, body, 
    html{
        height: 100%;
        width: 100%;
    }
    .app{
        margin: 0px auto;
        // color: #fff;
        // padding: @app-padding-head @app-padding-side;
        // padding-right: 0px;
        padding: 0px;
        position: relative;
        // overflow: hidden;
        // height: 100vh;

        .app-loading{
            z-index: 99999;
        }

        .app-item{
            // float: left;
        }
        .app-item.head{
            position: fixed;
            z-index: 301;
            top: 8px;
            left: 21px;
        } 
        .app-item.content{
            // width: 100%;
            height: 100%;
            min-height: 100vh;
            padding: 20px 0px 20px;
            width: 80%;
            margin: 0px auto;
        } 
        .draw-icon-a{
            width: 400px;
            height: 400px;
            position: relative;
            background: #000;
            .a{
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                margin: auto;

                width: 375px;
                height: 100px;
                border-radius: 100px;
                background: #fff;
            }
            .a1{
                
            }
            .a2{
                // height: 375px;
                // width: 100px;
                // border-radius: 100px;
                // background: #000;
                transform: rotate(90deg);
            }
        }
        .debug-data{
            background: #fff;
            color: #333;
            position: fixed;
            top: 50%;
            left: 10px;
            z-index: 999;
            .clear-debug{
                text-align: right;
            }
        }
        .global-connect-box{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            text-align: center;
        }
        .test-fun{
            position: fixed;
            top: 20px;
            right: 50%;
            transform: translateX(50%);
            z-index: 10000;
            color: #f83838;
        }
        .quit-box{
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
</style>