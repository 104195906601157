import Vue from "vue";
import {singleProxy, common, params, appData, userHand} from './exportEnter'
import callFun from './callFun'
import errorCode from './errorCode'
import text from '../text/text'
import confirm from './confirm'
import request from './request'
import userStatusControl from './userStatusControl'
import enums from './enums'
import VueClipboard from 'vue-clipboard2'
import courtClass from '@/API/page/court'
import errorHandClass from './errorHand'

Vue.prototype.singleProxy = singleProxy
Vue.prototype._common = common
Vue.prototype.PARAMS = params
Vue.prototype.TEXT = text
Vue.prototype.ERRORCODE = errorCode
Vue.prototype._errorCode = errorCode
Vue.prototype.ENUMS = enums

Vue.prototype._confirm = new confirm()
Vue.prototype._callFun = new callFun()
Vue.prototype.appDataClass = appData
Vue.prototype._userHand = new userHand()
Vue.prototype._request = new request()
Vue.prototype._userStatus = new userStatusControl()
Vue.prototype.courtApi = new courtClass()
Vue.prototype._errorHand = new errorHandClass()

Vue.use(VueClipboard)

// export {common, errorHand, singleProxy, params, appData}
