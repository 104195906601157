export default {
    courtList: {
        url: '/tennis_api/rest/v1/venue/getTennisVenueList',
        login: false,
        serve: 'tennis'
    },
    courtTimeList: {
        url: '/tennis_api/rest/v1/venue/getTimeListByVenueId',
        login: false,
        serve: 'tennis'
    },
    // 获取会员卡信息，参数 clientId -- 1874
    getCard: {
        url: '/tennis_api/rest/v1/card/getClientCardListByClientId?clientId=1874',
        serve: 'tennis'
    },
    submitOrder: {
        url: '/tennis_api/rest/v1/bookRecord/addBookRecord',
        serve: 'tennis'
    },

        // 获取会员卡定场地的费用
    // https://court.ikicker.cn/tennis_api/rest/v1/card/getVipPirceByTimeId?cardId=2&timeIds=332

    // 预定场地
    /* 会员卡预定参数
        { 
            "bookDate": "2023-06-15",
            "startEndTime": "18:00-19:00", 
            "payAmount": 100, 
            "siteName": "4号场", 
            "siteId": 21, 
            "bookPurpose": 4, 
            "payWay": 3, 
            "venueId": 8, 
            "venueName": "温菲尔德网球俱乐部（兰观绿洲馆）", 
            "bookWay": 0, 
            "timeIds": [332], 
            "bookerContact": "15010193341", 
            "bookerName": "小刘", 
            "remarks": "", 
            "clientCardId": 203 
        }

        bookDate: "2023-06-30"
        bookPurpose: 4
        bookWay: 0
        bookerContact:"15010193341"
        bookerName:"乐乐"
        clientCardId: 203
        payAmount: 200
        payWay: 3
        remarks : ""
        siteId: 26
        siteName: "3号场"
        startEndTime: "18:00-20:00"
        timeIds : [388]
        venueId :"10"
        venueName: "温菲尔德国家馆旗舰中心"


    3ce75253b35b7eecf2ea1eb1c33f9ddd80ea8c5332cf0cb894ccab85f1570522
    */

        /*
            "bookDate": "2023-06-16",
            "startEndTime": "16:00-19:00",
            "payAmount": 450,
            "siteName": "4号场",
            "siteId": 21,
            "bookPurpose": 4,
            "payWay": 2,
            "venueId": 8,
            "venueName": "温菲尔德网球俱乐部（兰观绿洲馆）",
            "bookWay": 0,
            "timeIds": [330, 331, 332],
            "bookerContact": "15010193341",
            "bookerName": "乐乐",
            "remarks": ""
        */ 



    // https://court.ikicker.cn/tennis_api/rest/v1/bookRecord/addBookRecord
}
