export default {
    name: '',
    props: {
    },
    data() {
        return {};
    },
    methods: {
        htmlFontSizeSet(){

            let width = 667, height = 375
            let fontSize = width / 100
            let px = width / height
            let html = document.getElementById('golaxy-gaotu-app-html')
            let {width: win_width, height: win_height} = this.windowSizeGet()
            let data = this._common.getCurrentSize(win_width, win_height, 'width')
            if(data.height > win_height){
                data = this._common.getCurrentSize(win_width, win_height, 'height')
            }
            fontSize = data.width / 100
            this._common.appSize = data
            fontSize = fontSize * 10
            this.appStyle = {
                width: `${data.width}px`,
                sizeData: {
                    ...data,
                    fontSize
                }
            }
            if(!html){
                return
            }
            this._common.appSize = {
                ...data,
                fontSize
            }
            html.style.fontSize = `${fontSize}px`
            this.htmlFontSize = fontSize
            this.appSizeData = {
                ...data,
                fontSize
            }
            this.appData.setStoreItem({appStyle: this.appStyle})
        },
        windowSizeGet(){
            let width, height
            let html = document.getElementById('golaxy-gaotu-app-html')
            if(html && html.offsetWidth > 0 && html.offsetHeight > 0){
                width = html.offsetWidth, height = html.offsetHeight
            }else{
                width =  window.innerWidth, height = window.innerHeight
            }
            return {width, height}
        },
    },
    created() {},
    components: {},
    computed: {},
    watch: {},
    mounted() {},
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}