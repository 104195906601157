<template>
    <div class="global-com-order-list">
        <van-dialog v-model="status" @confirm="confirm" :showCancelButton="false">
            <div class="order-list-body mini-scrollbar">
                <div class="order-item" v-for="v in orderListCul" :key="v.id">
                    <van-card
                    :price="v.payAmount"
                    :title="v.venueName"
                    :thumb="v.venuePicOne"
                    >
                        <span class="num-box" slot="num">
                            {{v.createTime}}
                        </span>
                        <span class="desc-box" slot="desc">
                            {{`${v.bookDate} | ${v.startEndTime} | ${v.siteName}`}}
                        </span>
                    </van-card>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
    import apiClass from '@/API/page/app'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                api: new apiClass(),
                current: 1, 
                size: 100,
                status: false,
                orderList: [],
                courtList: [],
                total: 0

            };
        },
        methods: {
            async courtListInit(){
                let res = await this.courtApi.courtList()
                console.log(res)
                if(!res) return
                this.courtList = res.venueList
            },
            async open(){
                await this.orderListLoad()
                this.status = true
            },
            async orderListLoad(){
                let {current, size} = this
                let res = await this.api.orderList({current, size})
                if(!res) return
                let {pages, total, bookRecords} = res || {}
                this.current = pages || this.current
                this.total = total || 0
                this.orderList = bookRecords || []
                console.log(this._common.deepCopy(this.orderList))
            },
            confirm(){
                this.status = false
            }
        },
        created() {
            this.courtListInit()
        },
        components: {},
        computed: {
            // courtListObj(){
            //     let {orderList: list} = this
            //     let rd = {}
            //     for(let i in list)
            // },
            orderListCul(){
                return this.orderList
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .global-com-order-list{
        .order-list-body{
            .order-item+ .order-item{
                margin-top: 20px;
            }
            height: 50vh;
        }
    }
</style>