import Vue from "vue";
import VueRouter from "vue-router";
import {common} from '@/global/js/exportEnter'
const Home = () => import(/* webpackChunkName: "index" */ "../views/index/index.vue");


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
const replaceFun = VueRouter.prototype.replace
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
	return replaceFun.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const requireComponent = require.context("@/router", false, /\.js$/)
let files = requireComponent.keys()
let list = []
files.forEach( paths => {
    let componentName = paths.replace(/^\.\//, "").replace(/\.\w+$/, "")
    if(componentName == 'index') return
    let com = requireComponent(paths)
    com = com.default || com
    // list[componentName] = com
    list.push(com)
});
console.log(list)
const routes = [
	
	{
		path: "/",
		name: "Home",
		component: Home,
		backFun: 'quit_app'
	},
	{
		path: '*',
		redirect: '/',
		component: Home,
	},
    ...list
];

const router = new VueRouter({
	mode: "history",
	// base: process.env.BASE_URL,
	base: '/',
	routes,
});
let routeList = {}
function routeListSave(list, par){
	if(par) par = par + '/'
	else par = ''
	list = list || routes
	for(let i in list){
		let data = list[i]
		if(!data || !data.path) continue
		let {path, name, children} = data
		path = par + handPath(path)
		let tem_data = common.deepCopy(list[i])
		delete tem_data['children']
		delete tem_data['path']
		routeList[name] = {path, ...tem_data}
		if(!children || !children.length) continue
		routeListSave(children, path)
	}
}
function handPath(path, par){
	if(!path) return path
	// path = path.replace(new RegExp(":","gm"), ":{")
	path = path.split('/')
	for(let i in path){
		let tem = path[i]
		if(tem.indexOf(':') <= -1) continue
		tem = tem.replace(new RegExp(":","gm"), "{")
		tem = tem + '}'
		path[i] = tem
	}
	return path.join('/')
}
routeListSave()
Vue.prototype._routeList = routeList
export default router;
