<template>
    <div class="global-com-token-input">
        <van-dialog v-model="state" title="token失效" @confirm="confirm">
            <div class="input-body">
                <van-cell-group>
                    <van-field v-model="token" label="token" placeholder="请输入token" />
                    <van-field v-model="accountId" label="accountId" placeholder="请输入accountId" />
                    <van-field v-model="openId" label="openId" placeholder="请输入openId(选填)" />
                </van-cell-group>
            </div>
        </van-dialog>
    </div>
</template>
<script>
// "wechatPic": "https://qiniu.ikicker.cn/1685261283738.jpg",
// "accountId": 1421,
// "wechatOpenId": "o1djd5Gu8IZHIAkD2L_waIDr2Gn0",
// "phone": "15010193341",
// "wechatName": "微信用户",
// "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIxNDIxIiwiZXhwIjoxNjkxODA4MjA1fQ.CNXFbKAXrdqlpQljJOMRGJ8Wgi-Q8XhzdVH9N15QbRk"
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                state: false,
                token: null,
                accountId: null,
                openId: null,
                appData: new this.appDataClass({key: 'app'}),
                response: null
            };
        },
        methods: {
            open(){
                return new Promise(res => {
                    this.response = res
                    this.token = null
                    this.state = true
                })
            },
            confirm(){
                // if(this.token) this.appData.setItem({'token': this.token})
                let {token, accountId, openId} = this
                let items = {
                    token, accountId, openId
                }
                this.appData.setItem(items)
                this.response()
            },
        },
        created() {},
        components: {},
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
        // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIxNDIxIiwiZXhwIjoxNjkxODA4MjA1fQ.CNXFbKAXrdqlpQljJOMRGJ8Wgi-Q8XhzdVH9N15QbRk
        // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIxNDIxIiwiZXhwIjoxNjkxMzE0NDgzfQ.grmHokNlZ6UVD_cIi3xg8SvYMbmyKWfNsO7GTGQTVqA
    }
</script>
<style lang="less">
    .global-com-token-input{
        .van-dialog__header{
            padding-top: 14px;
        }
    }
    
</style>