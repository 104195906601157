export default {
    'checkToken': {
        url: '/tennis_api/rest/v1/account/checkToken',
        serve: 'tennis',
        login: false
    },
    // /tennis_api/rest/v1/bookRecord/getAllBookRecordByState?state=-1&current=1&size=10
    'orderList': {
        url: '/tennis_api/rest/v1/bookRecord/getAllBookRecordByState',
        serve: 'tennis',
        login: false
    }
}