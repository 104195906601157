import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class app extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'app'
        let name = 'app'
        opt = {localKey, name}
        super(opt)
    }

    async checkToken(opt){
        opt = opt || {}
        let {token} = opt
        if(!token) return false
        let options = {
            ...opt,
            method: 'get',
            fullData: true,
            headers: {
                Authorization: token
            },
            data: {
                token
            },
        }
        let res = await this.requestAction('checkToken', options)
        let state = false
        if(res && res.code == this.PARAMS.success_code) state = true
        return state
    }

    async orderList(opt){
        opt = opt || {}
        let {state = -1, current = 1, size = 100} = opt
        let options = {
            ...opt,
            method: 'get',
            data: {
                state,
                current,
                size
            },
        }
        let res = await this.requestAction('orderList', options)
        return res
    }
}

export default singleProxy(app)