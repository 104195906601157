



const requireComponent = require.context("@/API/url", false, /\.js$/)
let files = requireComponent.keys()
let list = {}
files.forEach( paths => {
    let componentName = paths.replace(/^\.\//, "").replace(/\.\w+$/, "")
    let com = requireComponent(paths)
    com = com.default || com
    list[componentName] = com
});


export default {
    ...list
}