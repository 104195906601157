import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class court extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'court'
        let name = 'court'
        opt = {localKey, name}
        super(opt)
    }

    async courtList(opt){
        opt = opt || {}
        let {cacheData = true} = opt
        let options = {
            ...opt,
            cacheData,
            method: 'get',
            data: {
            },
        }
        let res = await this.requestAction('courtList', options)
        return res
    }

    async courtTimeList(opt){
        // ?venueId=10&queryDate=2023-06-12
        let {venueId, queryDate} = opt || {}
        if(!queryDate || !venueId) return false
        opt = opt || {}
        let options = {
            ...opt,
            method: 'get',
            data: {
                venueId,
                queryDate
            },
        }
        let res = await this.requestAction('courtTimeList', options)
        return res
    }

    async submitOrder(opt){
        opt = opt || {}
        let options = {
            ...opt,
            method: 'post',
            headers:{
                'Content-Type': 'application/json;charset=UTF-8' 

            },
            data: {
                ...opt,
            },
        }
        let res = await this.requestAction('submitOrder', options)
        return res
    }
}

export default singleProxy(court)