<template>
    <div class="global-com-quit">
        <el-button type="text" @click="quit">
            <span class="text-mini">退出登录</span>
        </el-button>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
        },
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                accountId: '1874',
                openId: 'o1djd5B0ubfc-SCk1aErOQlLCzAE',
                token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIxODc0IiwiZXhwIjoxNjkxOTMzOTYyfQ.f6gHbDW3b0W1qEaYbHYEDHN913eBA8JqM056GV_EWbQ'
            };
        },
        methods: {
            quit(){
                this.appData.removeItem({
                    'token': true,
                    'accountId': true,
                    'openId': true,
                })
                this.$emit('quit')
            }
        },
        created() {},
        components: {},
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>